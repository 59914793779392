import axios from "axios";
import React, { useContext } from "react";
import { MdUpdate } from "react-icons/md";
import { RiSignalWifi3Fill, RiSignalWifiOffLine } from "react-icons/ri";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import styled from "styled-components";
import { GlobalState } from "../../GlobalState.js";
import ToggleButton from "../utils/ToggleButton";
import Tooltip from "../utils/Tooltip.js";
import { editAccess, formatDate } from "../utils/utils.js";


const Container = styled.div`
 box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 0.5rem;
  position: relative;


  border: 2px solid #fff;
  background-color: #fff;
  &:hover {
    border: 2px solid var(--primary);
  }
  min-width: 260px;
  margin: 0.3rem;
`;

const LocationName = styled.h5`
  word-wrap: break-word;
  max-width: 90%;
`;

const TimeElapsed = styled.p`
  font-size: 0.7rem;
  font-weight: bold;
  text-align: end;
  display: flex;
  align-items: center;
  @media (min-width: 61.313em) {
    text-align: end;
  }
`;
const TopLine = styled.div`
display: flex;
align-items: center;
gap: 0.5rem;  
`;

const BottomLine = styled.div`
display: flex;
align-items: center;
gap: 0.5rem;
justify-content: space-between;
margin-top: 0.5rem;
`;



export default function SolenoidBoxComponent({ sensor }) {
  const history = useHistory();

  const location = useLocation();
  const state = useContext(GlobalState);
  const [solenoid, setSolenoid] = React.useState(sensor);
  const { solenoid_id, valve_on, sensor_online, updatedAt } = solenoid;

  const [solenoidStatus, setSolenoidStatus] = React.useState(valve_on === '1');




  const toggleSolenoid = async (id) => {
    try {
      const solenoidStatusResponse = await axios.patch(
        `${process.env.REACT_APP_API}/sensor/solenoid?solenoid_id=${id}`,
        {
          valve_on: !solenoidStatus ? '1' : '0',
        },
        {
          headers: { Authorization: state.token[0] },
        }
      );

      if (solenoidStatusResponse.data) {
        setSolenoidStatus(!solenoidStatus);

      }
    }
    catch (err) {
      console.log(err);
    }


  }

  const fetchData = async (id) => {
    try {
      const solenoidStatusResponse = await axios.get(
        `${process.env.REACT_APP_API}/sensor/solenoid?solenoid_id=${id}`,
        {
          headers: { Authorization: state.token[0] },
        }
      );

      if (solenoidStatusResponse.data) {
        setSolenoid(solenoidStatusResponse.data.data)
      }
    } catch (err) {
      console.log(err);
    }
  }

  React.useEffect(() => {
    const interval = setInterval(() => {
      fetchData(solenoid_id);
    }, 10000);

    return () => clearInterval(interval);
  }, [solenoid_id]);
  return (
    <>
      <Container>
        <TopLine>
          {sensor_online ? (
            <RiSignalWifi3Fill size={20} color={"green"} />
          ) : (
            <Tooltip
              content={<>Motor is offline.</>}
              direction="right"
              delay="0"
            >
              <RiSignalWifiOffLine size={20} color={"red"} />
            </Tooltip>
          )}
          <LocationName>
            {sensor.location_name}
          </LocationName>
        </TopLine>


        <BottomLine>
          <TimeElapsed>
            <MdUpdate /> &bull; &nbsp;
            {formatDate(updatedAt)}
          </TimeElapsed>
          <ToggleButton
            disabled={!editAccess(state.userAPI)}
            id={solenoid_id}
            checked={solenoidStatus}
            onChange={() => toggleSolenoid(solenoid_id)}
          ></ToggleButton>
        </BottomLine>

      </Container >
    </>
  );
}

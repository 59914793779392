import PropTypes from "prop-types";
import React from "react";
import "./RadioButton.css";

const RadioButton = ({ id, name, checked, onChange, disabled }) => {
    function handleClick() {
      if (!disabled) {
        onChange(!checked); 
      }
    }
  
    return (
      <div className="radio-button-container">
        <input
          type="radio"
          name={name}
          className="radio-button-input"
          id={id}
          checked={checked}
          onChange={() => {}}
          disabled={disabled}
        />
        {id && (
          <label className="radio-button-label" onClick={handleClick} htmlFor={id}>
            <span
              className={`radio-button-circle ${checked ? "checked pulse" : ""} ${disabled ? "disabled" : ""}`}
            >
              {checked && <span className="radio-button-dot" />}
            </span>
          </label>
        )}
      </div>
    );
  };
  

RadioButton.defaultProps = {
    disabled: false,
};

RadioButton.propTypes = {
    id: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string,
    disabled: PropTypes.bool,
};

export default RadioButton;
